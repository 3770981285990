<script>
import Base from "@backend/Base.vue";
import draggable from "vuedraggable";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";
export default {
  name: "BoPrestasi",
  components: {
    draggable
  },
  extends: Base,
  data() {
    return {
      Name: "BoPrestasi",
      aprImg: [],
      aprImgInput: [],
      prestasi: [],
      isEdit: false
    }
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData();
  },
  computed: {
    prestasiDesc: {
      get() {
        return (this.prestasi.as_val || {}).desc;
      },
      set(newValue) {
        $("[error='prestasi_desc']").html(
          600 - newValue.length < 0
            ? `<small class='error'>Input max ${600} characters</small>`
            : 600 - newValue.length === 0
            ? ""
            : `<small class="mb-0 text-info">${600 - newValue.length} characters left</small>`
        );
        this.prestasi.as_val.desc = newValue;
      },
    },
  },
  methods: {
    submitPrestasi(e) {
      if (e && e.btnLoading()) return;
      this.prestasi.type = "updateTextPrestasi";
      if (this.prestasi.as_name.length > 0) {
        $("[error='as_name']").html("");
      }
      if (this.prestasi.as_val.desc.length > 0) {
        $("[error='as_val']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        this.prestasi,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required") {
                  textError = "Input Required";
                  $("[error=" + k + "]").html(
                      "<span class='error'>" + textError + "</span>"
                  );
                }
              });
            }
            return;
          }
          if (resp.error) {
            return Gen.info(resp.message, "danger", 3000, ".prestasi-info1");
          }
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".prestasi-info1");
            setTimeout(() => {
              $('#clFormPrestasi').slideToggle();
              $([document.documentElement, document.body]).animate(
                {
                  scrollTop: $("#clFormPrestasi").offset().top - 650,
                },
                500
              );
            }, 2000);
            this.refreshData();
          }
        },
        "POST"
      );
    },
    addImage() {
      this.isEdit = false;
      $("#subPrestasi").html('Tambah');      
      let disp = $("#collapseImage").css("display")
      if(!disp){
        $(".mt-4.collapse").css("display","block")
      }

      $("#collapseImage").slideToggle();
      $("span.error").css("display","none");
      this.aprImgInput = {};
      this.aprImgInput = {
        ap_is_active: "Y",
      };
      this.aprImgInput.type = "addImagePrestasi";
      setTimeout(()=>{
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#collapseImage").offset().top,
          },
          500
        );
      },100)
    },
    editImage(v) {
      this.isEdit = true;       
      let disp = $("#collapseImage" + v.ap_id).css("display")
      if(!disp){
        $(".mt-4.collapse").css("display","block")
      }

      $("#collapseImage" + v.ap_id).slideToggle();  
      $("span.error").css("display","none");
      this.aprImgInput = v;
      this.aprImgInput.type = "updateImagePrestasi";
      setTimeout(()=>{
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#collapseImage" + this.aprImgInput.ap_id).offset().top,
          },
          500
        );
      },100)
    },
    submitImgPrestasi(e) {
      if (e && e.btnLoading()) return;
      if (this.aprImgInput.ap_img) {
        $("[error='ap_img']").html("");
      }
      if (this.aprImgInput.ap_name) {
        $("[error='ap_name']").html("");
      }
      if (this.aprImgInput.ap_alt_img) {
        $("[error='ap_alt_img']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        this.aprImgInput,
        (err, resp) => {
          if (e) e.btnUnloading();
          $("#subPrestasi").html(this.isEdit ? "Ubah" : "Tambah");      
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required") {
                  textError = "Input Required";
                  $("[error=" + k + "]").html(
                      "<span class='error'>" + textError + "</span>"
                  );
                }
              });
            }
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".prestasi-info2");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".prestasi-info2");
            this.refreshData();
            setTimeout(() => {
              $(".mt-4.collapse").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    endDragPrestasi(data) {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: data,
          type: "sortPrestasi",
        },
        (err, resp) => {
          this.refreshData()
        },
        "POST"
      );
    },
  },
  watch: {
    "aprImgInput.ap_name"(v) {
      this.aprImgInput.ap_alt_img = v;
      if (v.length > 0) {
        $("[name='ap_name']").valid();
        $("[error='ap_alt_img']").html($("[error='ap_name']").html());
      } else {
        $("[name='ap_name']").valid();
        $("[error='ap_alt_img']").html($("[error='ap_name']").html());
      }
    },
  }
}
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Ubah Konten Prestasi</h5>
          </div>
          <div class="card-body">
            <VForm @resp="submitPrestasi" method="post">
              <div class="row">
                <div class="prestasi-info1 col-12"></div>
                <div class="col-md-6">
                  <BoField
                    name="as_name"
                    :label="'Judul'"
                    v-model="prestasi.as_name"
                    v-bind="validation('as_name')"
                    :attr="{placeholder: 'e.g. Juara Kaligrafi'}"
                    mandatory
                  ></BoField>
                </div>
                <div class="col-md-6">
                  <BoField name="as_val" mandatory :label="'Deskripsi'">
                    <textarea
                      class="form-control"
                      minlength="10"
                      rows="5"
                      name="as_val"
                      v-model="prestasiDesc"
                      v-bind="validation('as_val')"
                      placeholder="e.g. Kami meraih berbagai penghargaan dan prestasi"
                    ></textarea>
                    <div error="prestasi_desc"></div>
                  </BoField>
                </div>
                <div class="col-12 text-right">
                  <button
                    v-if="moduleRole('Edit')"
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Gambar Prestasi</h5>
          </div>
          <div class="card-body">
            <draggable v-if="moduleRole('Edit')" v-model="aprImg" class="row gutter-20" @end="endDragPrestasi(aprImg)">
              <div style="cursor:move;" class="col-md-3 col-lg-20" :id="'row' + vpres.ap_id"
                v-for="(vpres, key) in aprImg" :key="key">
                <div class="wrap_slider_img" style="background-color: #ececec;">
                  <img :src="uploader(vpres.ap_img)" class="img-responsive" width="100%" />
                  <div class="slider_name">
                    <p>{{ vpres.ap_name }}</p>
                  </div>
                  <div class="bullet-cta">
                    <a v-if="moduleRole('Edit')" data-toggle="collapse" @click="editImage(vpres)" href="javascript:;" class="bullet_edit" v-tooltip="'Ubah'"><i
                        class="ti-marker-alt"></i></a>
                    <a v-if="moduleRole('Edit')" href="javascript:;" class="bullet_change_status bg-warning" @click="
                        changeStatusDynamic(
                          vpres,
                          'ap_is_active',
                          'ap_id',
                          'AppPrestasi',
                          undefined,
                          `collapseImage${isEdit ? vpres.ap_id : ''}`
                        )
                      " v-tooltip="'Ubah Status'"><i class="ti-settings"></i></a>
                    <a v-if="moduleRole('Delete')" href="javascript:;" @click="deleteItemCat(vpres.ap_id, vpres, undefined, `collapseImage${isEdit ? vpres.ap_id : ''}`)" class="bullet_delete" v-tooltip="'Hapus'"><i
                        class="far fa-trash-alt"></i></a>
                  </div>
                  <span class="label label-success" v-if="vpres.ap_is_active == 'Y'">Active</span>
                  <span class="label label-danger" v-else>Inactive</span>
                </div>
              </div>
              <div class="col-md-3 col-lg-20" :class="aprImg.length > 3 ? 'mt-3' : ''">
                <a v-if="moduleRole('Add')" class="wrap_upload" data-toggle="collapse" @click="addImage" href="#collapseImage" role="button"
                  aria-expanded="false" aria-controls="collapseImage">
                  <div class="ic_wrap">
                    <i class="fas fa-plus"></i>
                    <p>Tambah Gambar Prestasi</p>
                  </div>
                </a>
              </div>
            </draggable>
            <div v-else class="row gutter-20">
              <div class="col-md-3 col-lg-20" :id="'row' + vpres.ap_id"
                v-for="(vpres, key) in aprImg" :key="key">
                <div class="wrap_slider_img" style="background-color: #ececec;">
                  <img :src="uploader(vpres.ap_img)" class="img-responsive" width="100%" />
                  <div class="slider_name">
                    <p>{{ vpres.ap_name }}</p>
                  </div>
                  <div class="bullet-cta">
                    <a v-if="moduleRole('Edit')" data-toggle="collapse" @click="editImage(vpres)" href="javascript:;" class="bullet_edit" v-tooltip="'Ubah'"><i
                        class="ti-marker-alt"></i></a>
                    <a v-if="moduleRole('Edit')" href="javascript:;" class="bullet_change_status bg-warning" @click="
                        changeStatusDynamic(
                          vpres,
                          'ap_is_active',
                          'ap_id',
                          'AppPrestasi'
                        )
                      " v-tooltip="'Ubah Status'"><i class="ti-settings"></i></a>
                    <a v-if="moduleRole('Delete')" href="javascript:;" @click="deleteItemCat(vpres.ap_id, vpres, undefined, 'collapseImage')" class="bullet_delete" v-tooltip="'Hapus'"><i
                        class="far fa-trash-alt"></i></a>
                  </div>
                  <span class="label label-success" v-if="vpres.ap_is_active == 'Y'">Active</span>
                  <span class="label label-danger" v-else>Inactive</span>
                </div>
              </div>
              <div class="col-md-3 col-lg-20" :class="aprImg.length > 3 ? 'mt-3' : ''">
                <a v-if="moduleRole('Add')" class="wrap_upload" data-toggle="collapse" @click="addImage" href="#collapseImage" role="button"
                  aria-expanded="false" aria-controls="collapseImage">
                  <div class="ic_wrap">
                    <i class="fas fa-plus"></i>
                    <p>Tambahkan Prestasi</p>
                  </div>
                </a>
              </div>
            </div>
            <div v-if="isEdit ? moduleRole('Edit') : moduleRole('Add')" class="collapse mt-4" :id="isEdit ? 'collapseImage' + aprImgInput.ap_id : 'collapseImage'">
              <div class="card border">
                <div class="card-header">
                  <h5 class="card-title">
                    {{ (aprImgInput.ap_id ? "Ubah" : "Tambah") + " Gambar " + ObjectName }}
                  </h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="prestasi-info2 col-12"></div>
                    <div class="col-md-4 mb-3">
                      <BoField name="ap_img" mandatory class="mb-0">
                        <Uploader name="ap_img" :param="{ thumbnail: true }" type="eskul" uploadType="cropping" :deleted="false"
                          v-model="aprImgInput.ap_img"></Uploader>
                      </BoField>
                      <BoField
                        mandatory
                        name="ap_alt_img"
                        v-model="aprImgInput.ap_alt_img"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Juara Kaligrafi'
                        }"
                      ></BoField>
                    </div>
                    <div class="col-md-8 mb-3">
                      <VForm @resp="submitImgPrestasi" method="post">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="panel_group">
                              <div class="panel_heading">Prestasi</div>
                              <div class="panel_body">
                                <div class="row">
                                  <div class="col-12 col-info"></div>
                                  <div class="col-md-6">
                                    <BoField 
                                      name="ap_name" 
                                      :attr="{
                                        maxlength: '100',
                                        minlength: '3',
                                        placeholder: 'e.g. Juara Kaligrafi'
                                      }" 
                                      v-model="aprImgInput.ap_name" 
                                      mandatory
                                    ></BoField>
                                  </div>
                                  <div class="col-sm-4">
                                    <BoField name="ap_is_active">
                                      <radio name="ap_is_active" v-model="aprImgInput.ap_is_active" option="Y"
                                        :attr="validation('ap_is_active')">Active</radio>
                                      <radio name="ap_is_active" v-model="aprImgInput.ap_is_active" option="N">Inactive
                                      </radio>
                                    </BoField>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 mt-3 text-right">
                            <button type="submit" id="subPrestasi" class="btn btn-rounded btn-loading btn-info">
                              {{aprImgInput.ap_id ? 'Ubah' : 'Tambah'}}
                            </button>
                          </div>
                        </div>
                      </VForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>